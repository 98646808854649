import http from '@/utils/http.ts'
export const getPageInfo = (parmas:any)=> {
    return http.post("api/page/info", parmas)
}

export const savePage = (parmas:any)=> {
    return http.post("api/page/save", parmas)
}

export const listMyPages = (parmas:any)=> {
    return http.post("api/page/list", parmas)
}

export const deletePage = (parmas:any)=> {
    return http.post("api/page/delete", parmas)
}