import axios from 'axios';
import qs from 'qs'

// import {apiUrl} from '@/utils/config.ts'
// import store from '@/store/index'       //已经设置路径别名，否则相对路径引用
import { ElMessage, ElLoading } from "element-plus";
let loading:any;
const service = axios.create({
    timeout: 5000,                                   //超时时间
    baseURL: process.env.VUE_APP_URL,
	headers: {'content-type': 'application/x-www-form-urlencoded'},
	transformRequest: [function (data) {
		// `transformRequest` 允许在向服务器发送前，修改请求数据
		//只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
		return qs.stringify(data)
		// 结合create_headers里的内容，在这里又新增一条信息sex=man
		// 因此network中查看的结果是：name=xiaoming&age=12&sex=man
	}],
})
// http request 拦截器
service.interceptors.request.use(config => {
	const token = localStorage.getItem("token");
	console.log("httptoken:",token);
	if (config.headers) {
		config.headers['Authorization'] =token||''
		config.headers['Content-Type'] ='application/x-www-form-urlencoded; charset=UTF-8'
	}
    
    // loading = ElLoading.service({
    //     lock: true,
    //     text: 'Loading',
    //     background: 'rgba(0, 0, 0, 0.7)'
    // })
  
    return config
}, err => {
    return err
})
// http response 拦截器
service.interceptors.response.use(response => {
    // loading.close()
    // return response
    //接收返回数据..
    const res = response.data

    //判断状态code是否为指定数值(0)..
    if (res.error_code != 0) {
       showMessage(res.error_message,"error",true)
    }
    return res
}, err => {
    console.log("33333333333333333")
    loading.close()
    return showMessage(err.message,"error",true)
})

//封装错误提示信息..
function showMessage(message:any , type:any , showClose = true) {
    ElMessage({
        showClose,          //是否显示X
        message,         //错误提示信息
        type,        //显示类型
        duration: 3 * 1000    //展示时间
    })
    return Promise.reject()
}
export default service;