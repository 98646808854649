import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Index from "../views/Index.vue";
import Item from '../views/Item.vue'
import Login from '../views/Login.vue'
import Edit from '../views/Edit.vue'
import Page from '../views/Page.vue'
import ItemAdd from '../views/ItemAdd.vue'
import ItemSetting from '../views/ItemSetting.vue'
import Wang from '../views/Wang.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/item',
    name: 'Item',
    component: Item
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/edit/:item_id/:page_pid/:page_id',
    name: 'Edit',
    component: Edit
  },
  {
    path: '/page/:item_id/:page_id',
    name: 'Page',
    component: Page
  },
  {
    path: '/item/add',
    name: 'ItemAdd',
    component: ItemAdd
  },
  {
    path: '/item/setting/:item_id',
    name: 'ItemSetting',
    component: ItemSetting
  },
  {
    path: '/wang',
    name: 'Wang',
    component: Wang
  }

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
