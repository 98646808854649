import http from '@/utils/http.ts'
export const listMyItems = (parmas:any)=> {
    return http.post("api/item/list", parmas)
}
export const getItemInfo = (parmas:any)=> {
    return http.post("api/item/info", parmas)
}

export const addItem = (parmas:any)=> {
    return http.post("api/item/add", parmas)
}

export const updateItem = (parmas:any)=> {
    return http.post("api/item/update", parmas)
}

export const deleteItem = (parmas:any)=> {
    return http.post("api/item/delete", parmas)
}

