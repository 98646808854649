import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as Icons from "@element-plus/icons-vue";
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import App from './App.vue'
import router from './router'
// import store from './store'
import { createPinia } from 'pinia'
import "./assets/common.scss"

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(ElementPlus)
app.mount('#app')




// 注册全局组件
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key as keyof typeof Icons])
})