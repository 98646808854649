// import { createStore } from 'vuex'

// export default createStore({
//   state: {
// 	token:""
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// }) 

import { defineStore } from 'pinia'

export const useStore = defineStore('main', {
  state:()=>({
    isLogin:false,
    userInfo:{},
    showAsItem:true  //首页面，是否安项目模式显示
  }),
  getters:{
  },
  actions:{
    login(userInfo:any){
      this.isLogin=true;
      this.userInfo=userInfo;
      localStorage.setItem("token",userInfo.user_token);
      console.log("111token:",localStorage.getItem("token"));
    },
    logout(){
      this.isLogin=false;
      this.userInfo={};
      localStorage.removeItem("token");
    },
    updateUserInfo(userInfo:any){
      this.isLogin=true;
      this.userInfo=userInfo;
    },
    setShowAsItem(showAsItem:boolean){
      this.showAsItem=showAsItem;
    }
  }
})