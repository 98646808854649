import http from '@/utils/http.ts'


export const login = (parmas:any)=> {
    return http.post("api/user/login", parmas)
}

export const info = (parmas:any)=> {
    return http.post("api/user/info", parmas)
}

export const regist = (parmas:any)=> {
    return http.post("api/user/register", parmas)
}